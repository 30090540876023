import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './services/user/user.service';
import { CompanyService } from './services/company/company.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationInterceptor } from './services/interceptor';
import { AzureAdService } from './services/azure-ad/azure-ad.service';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '../login/effects/auth.effect';
import { StoreModule } from '@ngrx/store';
import { authFeatureKey, reducer } from '../login/reducers/auth.reducer';
import { MaterialModule } from './modules/material.module';
import { MatTableResponsiveDirective } from "./directives/mat-table-responsive/mat-table-responsive.directive";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { FlexModule, FlexLayoutModule } from "@angular/flex-layout";
import { AlertMessagesBoxComponent } from './components/alert-messages-box/alert-messages-box.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { TraineeMiniRowComponent } from './components/trainee-mini-row/trainee-mini-row.component';
import { RefreshButtonComponent } from "./components/refresh-button/refresh-button.component";
import { GoBackButtonComponent } from './components/go-back-button/go-back-button.component';
import { InputWithOptionsComponent } from './components/input-with-options/input-with-options.component';
import { GecoSpinnerComponent } from './components/geco-spinner/geco-spinner.component';
import { MomentUtcDateAdapter } from './adapters/moment-utc-date.adapter';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { AuthenticationService } from './services/authentication/authentication.service';

@NgModule({
  declarations: [
    MatTableResponsiveDirective,
    AlertMessagesBoxComponent,
    ConfirmationDialogComponent,
    TraineeMiniRowComponent,
    RefreshButtonComponent,
    GoBackButtonComponent,
    InputWithOptionsComponent,
    GecoSpinnerComponent,
    SafeUrlPipe,
  ],
  imports: [
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FlexModule,
    CommonModule,
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(authFeatureKey, reducer),
    FlexLayoutModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
  ],
  exports: [
    MaterialModule,
    MatTableResponsiveDirective,
    RouterModule,
    ReactiveFormsModule,
    FlexModule,
    AlertMessagesBoxComponent,
    TraineeMiniRowComponent,
    RefreshButtonComponent,
    GoBackButtonComponent,
    InputWithOptionsComponent,
    GecoSpinnerComponent,
    SafeUrlPipe,
  ],
  providers: [
    UserService,
    CompanyService,
    AuthenticationService,
    ApplicationInterceptor,
    SafeUrlPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApplicationInterceptor, multi: true },
    AzureAdService,
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: DateAdapter,
      useClass: MomentUtcDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SharedModule {
}
