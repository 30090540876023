import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from "@ngrx/store";
import { lastValueFrom, take } from 'rxjs';
import { HttpCallerService } from '../http-caller.service';
import { UserModel } from '../../models/user.model';
import { ActivationUserDto } from '../../../user/dtos/activation-user.dto';
import { AccountModel } from '../../models/account.model';
import { AzureRolesEnum } from '../../enums/azure-roles.enum';
import { AppState } from "../../../reducers/reducer";
import {getUser} from "../../../login/selectors/auth.selector";
import { environment } from '../../../../environments/environment';
import { UserStatusDto } from '../../../user/dtos/user-status.dto';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends HttpCallerService {

  constructor(
    protected readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    super(http);
  }

  static getCustomAttributeFullName(attributeName: string): string {
    return (
      'extension_' + environment.azureAdCustomParametersClientId.replace(/-/g, '') + '_' + attributeName
    );
  }

  static getFiscalCodeFromUser(user: UserModel) {
    return user[AuthenticationService.getCustomAttributeFullName('fiscalCode')];
  }

  static isPassedUserAUser(user: UserModel) {
    const data = user[AuthenticationService.getCustomAttributeFullName('Roles')];
    return data == AzureRolesEnum.user;
  }

  async getUsersByFiscalCodes(fiscalCodes: string[]): Promise<UserModel> {
    return this.post('/user/fiscal-codes', fiscalCodes);
  }

  async getAllUsers(): Promise<UserModel[]> {
    return this.get<UserModel[]>('/user');
  }

  async getUser(id: string): Promise<UserModel> {
    return this.get<UserModel>('/user/' + id);
  }

  async isUser(): Promise<boolean> {
    const accountModel = await this.getAccountModelFromStore();
    return accountModel.idTokenClaims?.extension_Roles === AzureRolesEnum.user;
  }

  async getAccountModelFromStore(): Promise<AccountModel> {
    const a$ = this.store.select(getUser).pipe(take(1));
    return await lastValueFrom(a$);
  }

  async validateUserBySecretUuid(uuid: string): Promise<void> {
    await this.post('/user/activate-user-by-uuid/', {uuid});
  }

  async setNewUser(body: ActivationUserDto): Promise<void> {
    await this.post('/user/create-validation-uuid/', body);
  }

  async createNewUser(body: UserModel): Promise<any> {
    const dataToSend = this.fromUserToUserStatusDto(body);
    return this.post('/user/create-new', dataToSend);
  }

  fromUserToUserStatusDto(user: UserModel): UserStatusDto {
    const dataToSend = new UserStatusDto();
    dataToSend.id = user.id;
    dataToSend.fiscalCode = user[AuthenticationService.getCustomAttributeFullName('fiscalCode')]?.toString() || '';
    dataToSend.active = !!user[AuthenticationService.getCustomAttributeFullName('activated')];
    dataToSend.blocked = !!user[AuthenticationService.getCustomAttributeFullName('blocked')];
    dataToSend.role = user[AuthenticationService.getCustomAttributeFullName('Roles')]?.toString();
    dataToSend.displayName = user.displayName;
    dataToSend.companyName = user[AuthenticationService.getCustomAttributeFullName('companyName')]?.toString();
    dataToSend.mail = user.mail;
    return dataToSend;
  }
}
