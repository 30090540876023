// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  enableMsal: true,
  azureAdTenantDomainName: 'checkcantiere',
  azureAdClientId: 'e7499bf5-92f1-409d-94cf-7648075757af',
  azureAdCloudInstance: 'https://login.onmicrosoftonline.com/',
  azureAdTenantId: 'd2a2165d-2df1-452d-bc70-2d557e9b6316',
  baseAppUrl: 'https://gecodev.overflo.srl',
  azureAdKnownAuthorities: [`https://checkcantiere.b2clogin.com/`],
  azureSingUpSignInEndpoint: 'https://checkcantiere.b2clogin.com/checkcantiere.onmicrosoft.com/B2C_1_SignInAndUp/',
  backendBasePath: 'https://gecodev.overflo.srl/api/v1',
  azureAdCustomParametersClientId: 'da6e3035-1555-43fe-ba4a-6a7e97be2429',
  monthsForPrevediCheck: 12,
  basePrevediPath: 'https://prevedi.check-cantiere.it/api/v1',
  prevediUser: 'geco',
  prevediPassword: 'Vo9ries#',
  dynamicsEsebPath: 'https://eseb.crm4.dynamics.com',
  dynamicsAppId: 'ae48859d-58ac-eb11-8236-000d3ab28971'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
