import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {BrowserCacheLocation, InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {environment} from '../environments/environment';
import {MatDialogModule} from '@angular/material/dialog';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducers, metaReducers} from './reducers/reducer';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {LoginModule} from './login/login.module';
import {CommonModule} from '@angular/common';
import {HomeModule} from './home/home.module';
import {HttpClientModule} from '@angular/common/http';
import {MatTableModule} from '@angular/material/table';
import {AdminModule} from './admin/admin.module';
import {SharedModule} from './shared/shared.module';
import {CourseModule} from './course/course.module';
import {FullTableModule} from '@overflo-srl/full-table';
import {UserModule} from "./user/user.module";
import {PreRegistrationModule} from "./pre-registration/pre-registration.module";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {CptModule} from "./cpt/cpt.module";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    FullTableModule.forRoot(environment.backendBasePath),
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        authority: environment.azureAdCloudInstance + environment.azureAdTenantId,
        clientId: environment.azureAdClientId,
        knownAuthorities: environment.azureAdKnownAuthorities,
        redirectUri: environment.baseAppUrl
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback: () => {
          },
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      loginFailedRoute: '/login'
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ['https://api.myapplication.com/users/*', ['customscope.read']],
        [environment.baseAppUrl + '/about/', null]
      ])
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    CommonModule,
    LoginModule,
    HomeModule,
    HttpClientModule,
    AdminModule,
    MatTableModule,
    SharedModule,
    CourseModule,
    UserModule,
    PreRegistrationModule,
    MatDatepickerModule,
    MatMomentDateModule,
    CptModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true}},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
