import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRegistrationLinkComponent } from './components/user-registration-link/user-registration-link.component';
import {SharedModule} from "../shared/shared.module";
import {RouterModule} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";

@NgModule({
  declarations: [
    UserRegistrationLinkComponent
  ],
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        RouterModule
    ]
})
export class UserModule { }
