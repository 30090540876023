<div class="login-container">
  <div fxLayout="column" fxLayoutAlign="space-evenly center">

    <div style="margin: 20px">
      <img src="../../../assets/img/geco_home_350px.png" alt="Portale iscrizione ai corsi">
    </div>
    <div class="login-button-container">
      <button mat-raised-button color="primary" (click)="onEnterInPortalPressed()" class="login-button">
        <div class="login-button-content">
          <div>Accedi al portale</div>
          <mat-spinner [diameter]="24" class="login-loader" *ngIf="activeLoginFlow"></mat-spinner>
        </div>
      </button>
    </div>
    <a href="#" target="_blank" class="manual-link">
      Manuale di utilizzo
    </a>

    <img src="../../../assets/img/logo_ESEB.png" alt="Logo ESEB">
  </div>
</div>
