import { CompanyUserModel } from "./company-user.model";

export interface CompanyModel {
    id: number;
    createDate: Date;
    updateDate: Date;
    fiscalCode: string;
    givenName: string;
    contactMail: string;
    user?: CompanyUserModel;
    capeCode?: string;
}

export class CompanyModelFactory {
    static createEmpty(): CompanyModel {
        return {
            id: 0,
            createDate: new Date(),
            updateDate: new Date(),
            fiscalCode: "",
            givenName: "",
            contactMail: "",
        };
    }
}
