import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CourseFormComponent} from './components/course-form/course-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CourseService} from './services/course.service';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {CourseTableComponent} from './components/course-table/course-table.component';
import {FullTableModule} from '@overflo-srl/full-table';
import {CourseViewComponent} from './components/course-view/course-view.component';
import {RouterModule, Routes} from '@angular/router';
import {CourseEditComponent} from './components/course-edit/course-edit.component';
import {CoursePreRegistrationComponent} from './components/course-pre-registration/course-pre-registration.component';
import {MatSelectModule} from '@angular/material/select';
import {SharedModule} from '../shared/shared.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {PreRegistrationModule} from "../pre-registration/pre-registration.module";
import {CourseViewUserComponent} from './components/course-view-user/course-view-user.component';
import {EditionLineShowerComponent} from './components/edition-line-shower/edition-line-shower.component';
import {
  LessonsVisualizerDialogComponent
} from './components/lessons-visualizer-dialog/lessons-visualizer-dialog.component';
import { LessonLinePipe } from './pipes/lesson-line/lesson-line.pipe';
import { CourseLegendComponent } from './components/course-legend/course-legend.component';


@NgModule({
  declarations: [
    CourseFormComponent,
    CourseTableComponent,
    CourseViewComponent,
    CourseEditComponent,
    CoursePreRegistrationComponent,
    CourseViewUserComponent,
    EditionLineShowerComponent,
    LessonsVisualizerDialogComponent,
    LessonLinePipe,
    CourseLegendComponent,
  ],
  providers: [CourseService],
  exports: [RouterModule],
  imports: [
    RouterModule,
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    FlexModule,
    FullTableModule,
    MatSelectModule,
    FormsModule,
    ScrollingModule,
    PreRegistrationModule,
    FlexLayoutModule,
  ]
})
export class CourseModule {
}
