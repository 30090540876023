import { Pipe, PipeTransform } from '@angular/core';
import { LessonModel } from '../../models/lesson.model';

@Pipe({
  name: 'lessonsHours'
})
export class LessonsHoursPipe implements PipeTransform {

  transform(lessons?: LessonModel[]): number {
    let hours = 0;
    const millisecondsInAnHour = 3600000;
    lessons?.forEach((lesson) => {
      const startTime = new Date(lesson.startDate).getTime();
      const endTime = new Date(lesson.endDate).getTime();
      const hoursDiff = Math.abs(endTime - startTime) / millisecondsInAnHour;
      hours += hoursDiff;
    })
    return Math.round((hours + Number.EPSILON) * 100) / 100;
  }

}
