import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {lastValueFrom} from 'rxjs';
import {CourseModel, CourseModelFactory} from '../../shared/models/course.model';
import {RequestQueryBuilder} from "@nestjsx/crud-request";

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private readonly http: HttpClient) {
  }

  async createCourse(data: CourseModel): Promise<void> {
    const a$ = this.http.post(environment.backendBasePath + '/course', data);
    await lastValueFrom(a$);
  }

  async updateCourse(data: CourseModel): Promise<void> {
    const a$ = this.http.patch(environment.backendBasePath + '/course/' + data.id, data);
    await lastValueFrom(a$);
  }

  async getCourse(id: string): Promise<CourseModel>{
    const query = RequestQueryBuilder.create();
    query.setJoin({field: "checkCodes"})
    const fullPath = environment.backendBasePath + '/course/' + id + '?' + query.query();
    const a$ = await this.http.get<CourseModel>(fullPath);
    return await lastValueFrom(a$);
  }

  async getCategories(): Promise<any> {
    const a$ = this.http.get(environment.backendBasePath + '/course/get-categories');
    return await lastValueFrom(a$);
  }

  async getAllOpenEditions(id: number | undefined) {
    const a$ = this.http.get(environment.backendBasePath + `/course/get-open-editions/${id}`);
    return await lastValueFrom(a$);
  }
}
