import { TraineeModel, TraineeModelFactory } from "./trainee.model";
import { SubRegistrationModel } from "../../sub-registration/models/sub-registration.model";
import { WorkerAnagraphicInterface } from "../interfaces/worker-anagraphic.interface";
import { CompanyModel, CompanyModelFactory } from "../../admin/models/company.model";

export class TraineeWorkContractModel {
  id?: number;
  trainee?: TraineeModel;
  companyFiscalCode!: string;
  company?: CompanyModel;
  inLocalCE!: boolean;
  inOtherCE!: boolean;
  selfCertified!: boolean;
  type!: string;
  email?: string;
  phone?: string;
  createdDate?: Date | string;
  updatedDate?: Date | string;
  active!: boolean;
  dataOrigin?: string;
  subRegistrations?: SubRegistrationModel[];
}

export class TraineeWorkContractModelFactory {
  static createEmpty(): TraineeWorkContractModel {
    return {
      active: false,
      companyFiscalCode: "",
      company: CompanyModelFactory.createEmpty(),
      email: "",
      trainee: TraineeModelFactory.createEmpty(),
      inLocalCE: false,
      inOtherCE: false,
      phone: "",
      selfCertified: false,
      type: ""
    }
  }

  static createFromWorkerRegistry(workerRegistry: WorkerAnagraphicInterface): TraineeWorkContractModel {
    const trainee = TraineeModelFactory.createFromWorkerRegistry(workerRegistry);
    const traineeWorkContract = TraineeWorkContractModelFactory.createEmpty();
    traineeWorkContract.trainee = trainee;
    traineeWorkContract.companyFiscalCode = workerRegistry.companyFiscalCode;
    traineeWorkContract.active = true;
    return traineeWorkContract
  }
}
