import {createReducer, on} from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import {LocalStorageVariablesEnum} from "../../shared/enums/local-storage-variables.enum";

export const authFeatureKey = 'auth';

export interface State {
  accessToken: string | '';
  user: any | '';
  error: string | '';
}

export const initialState: State = {
  accessToken: localStorage.getItem(LocalStorageVariablesEnum.token) ? JSON.parse(localStorage.getItem(LocalStorageVariablesEnum.token) || '') : undefined,
  user: localStorage.getItem(LocalStorageVariablesEnum.user) ? JSON.parse(localStorage.getItem(LocalStorageVariablesEnum.user) || '') : undefined,
  error: '',
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, action) =>
    ( {...state, accessToken: action.accessToken, user: action.user}))
);


