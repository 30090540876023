export enum TraineeCategoryEnum{
  worker = 'worker',
  apprentice = 'apprentice',
  employee = 'clerk',
  employer = 'employer',
  other = 'other',
  unemployed = 'unemployed'
}

export enum TraineeCategoryTranslationEnum{
  worker = 'Operaio',
  apprentice = 'Apprendista',
  employee = 'Impiegato',
  employer = 'Datore di lavoro',
  unemployed = 'Disoccupato',
  other = 'Altro'
}

export function TranslateTraineeCategory(category: TraineeCategoryEnum | string): string {
  switch (category){
    case TraineeCategoryEnum.worker:
    case TraineeCategoryEnum.apprentice:
      return TraineeCategoryTranslationEnum.worker;
    case TraineeCategoryEnum.employee:
      return TraineeCategoryTranslationEnum.employee;
    case TraineeCategoryEnum.employer:
      return TraineeCategoryTranslationEnum.employer;
    case TraineeCategoryEnum.other:
      return TraineeCategoryTranslationEnum.other;
    case TraineeCategoryEnum.unemployed:
      return TraineeCategoryTranslationEnum.unemployed;
    default:
      return "";
  }
}

export function InverseTranslateTraineeCategory(category: TraineeCategoryTranslationEnum): string{
  switch (category){
    case TraineeCategoryTranslationEnum.worker:
    case TraineeCategoryTranslationEnum.apprentice:
      return TraineeCategoryEnum.worker;
    case TraineeCategoryTranslationEnum.employee:
      return TraineeCategoryEnum.employee;
      case TraineeCategoryTranslationEnum.employer:
      return TraineeCategoryEnum.employer;
    case TraineeCategoryTranslationEnum.other:
      return TraineeCategoryEnum.other;
    case TraineeCategoryTranslationEnum.unemployed:
      return TraineeCategoryEnum.unemployed;
  }
}

export function GetTraineeTypesWithLabel () {
  return [
    {value: TraineeCategoryEnum.employee, title: TraineeCategoryTranslationEnum.employee},
    {value: TraineeCategoryEnum.employer, title: TraineeCategoryTranslationEnum.employer},
    {value: TraineeCategoryEnum.worker, title: TraineeCategoryTranslationEnum.worker},
    {value: TraineeCategoryEnum.other, title: TraineeCategoryTranslationEnum.other},
  ];
}
