export enum BasePathEnum{
  admin= 'admin',
  user= 'user',
}

export enum SubdomainsEnum{
  course = 'course',
  user = 'user',
  view = 'view',
  cpt = 'cpt',
  home = 'home',
  companies = 'companies',
  create = 'create',
  edit = 'edit',
  preRegistration = 'pre-registration',
  edition = 'edition',
  trainee = 'trainee',
  certificateTimetable = 'certificate-timetable',
}

export class AbsolutePaths{
  static readonly userHome = '/' + SubdomainsEnum.home;
  static readonly adminHome = BasePathEnum.admin + '/' + SubdomainsEnum.home;
  static readonly adminCourse = BasePathEnum.admin + '/' + SubdomainsEnum.course;
  static readonly adminCompanies = BasePathEnum.admin + '/' + SubdomainsEnum.companies;
  static readonly adminCourseView = BasePathEnum.admin + '/' + SubdomainsEnum.course + '/' + SubdomainsEnum.view;
  static readonly adminPreRegistration = BasePathEnum.admin + '/' + SubdomainsEnum.preRegistration;
  static readonly courseView = SubdomainsEnum.course + '/' + SubdomainsEnum.view;
  static readonly traineeView = SubdomainsEnum.trainee + '/' + SubdomainsEnum.view;
  static readonly adminEdition = BasePathEnum.admin + '/' + SubdomainsEnum.edition;
}

export class RelativePaths{
  static readonly editionView = SubdomainsEnum.edition + '/' + SubdomainsEnum.view;
  static readonly editionCreate = SubdomainsEnum.edition + '/' + SubdomainsEnum.create;
  static readonly preRegistrationCreate = SubdomainsEnum.preRegistration;
}

