
<div *ngIf="!confirmed; else elseBlock" >Conferma utente in corso...</div>
<ng-template #elseBlock>

  Utente registrato con successo!

  <a routerLink="/login">
    Vai al login
  </a>

</ng-template>
