import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";

export abstract class HttpCallerService {

  protected constructor(protected readonly http: HttpClient) {
  }

  protected async get<T>(relativeUrl: string){
    const data$ = this.http.get<T>(environment.backendBasePath + relativeUrl);
    return await lastValueFrom(data$);
  }

  protected async post<T>(relativeUrl: string, body: any){
    const data$ = this.http.post<T>(environment.backendBasePath + relativeUrl, body);
    return await lastValueFrom(data$);
  }

  protected async patch<T>(relativeUrl: string, body: any){
    const data$ = this.http.patch<T>(environment.backendBasePath + relativeUrl, body);
    return await lastValueFrom(data$);
  }
}
