import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, catchError, filter, from, Observable, take, throwError} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {getToken} from '../../login/selectors/auth.selector';
import {AppState} from '../../reducers/reducer';
import {AzureAdService} from './azure-ad/azure-ad.service';
import {TraineeService} from './trainee/trainee.service';
import {logout} from '../../login/actions/auth.actions';

@Injectable()
export class ApplicationInterceptor implements HttpInterceptor {
  private backendToken$: Observable<string>;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private azureAdService: AzureAdService,
    private prevediService: TraineeService
  ) {
    this.backendToken$ = this.store.select(getToken);
  }

  private static addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  intercept(request: HttpRequest<any>, nextHttpHAndler: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.search('login') !== -1 ) {
      return nextHttpHAndler.handle(request); // No auth
    }

    if (request.url.search('prevedi') !== -1) {
      console.log('Skippato intercept per prevedi')
      return nextHttpHAndler.handle(request); // Handled in the prevedi module
    }

    from(this.azureAdService.refreshToken());
    return this.backendToken$.pipe(
      first(), // Non voglio che al cambiare del token partano altre richieste uguali a quella ricevuta
      switchMap(token => {
        const authReq = !!token ? ApplicationInterceptor.addToken(request, token) : request;

        return nextHttpHAndler.handle(authReq);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.store.dispatch(logout());
        } else if (error.status === 401) {
          return this.handle401Error(request, nextHttpHAndler);
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (localStorage.getItem('isRefreshing') !== 'true') {
      this.refreshTokenSubject.next(null);

      return this.azureAdService.refreshTokenNotAsync().pipe(
        switchMap((val) => {
          this.refreshTokenSubject.next(val.idToken);
          return next.handle(ApplicationInterceptor.addToken(request, val.idToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(ApplicationInterceptor.addToken(request, jwt));
        })
      );
    }
  }
}
