import {Component, OnInit} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {loginSuccess} from '../actions/auth.actions';
import {AppState} from '../../reducers/reducer';
import {getFullState, getUser} from '../selectors/auth.selector';
import {AzureAdService} from '../../shared/services/azure-ad/azure-ad.service';
import {lastValueFrom, take} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  activeLoginFlow: boolean = false;

  constructor(private readonly msalService: MsalService,
              private router: Router,
              private store: Store<AppState>,
              private readonly azureAdService: AzureAdService) {
  }

  ngOnInit(): void {
    sessionStorage.clear();
    const user = this.store.select(getUser);
  }

  async onEnterInPortalPressed(): Promise<void> {

    const a$ = this.store.select(getFullState).pipe(take(1));
    const data = await lastValueFrom(a$);

    if (data && data.accessToken) {
      this.store.dispatch(loginSuccess(data));
    } else {
      this.activeLoginFlow = true;
      this.azureAdService.getUserFromLoginPopupAndSaveJwt().subscribe((t) => {
        this.activeLoginFlow = false;
      }, (err) => {
        if (err.errorCode === 'user_cancelled') {
          this.activeLoginFlow = false;
        }
      });
    }
  }

}
