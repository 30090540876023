import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { AuthenticationService } from '../../../shared/services/authentication/authentication.service';
import {logout} from "../../../login/actions/auth.actions";
import {AppState} from "../../../reducers/reducer";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-user-registration-link',
  templateUrl: './user-registration-link.component.html',
  styleUrls: ['./user-registration-link.component.scss']
})
export class UserRegistrationLinkComponent implements OnInit {

  data!: string
  confirmed = false;
  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly authenticationService: AuthenticationService,
              private readonly store: Store<AppState> ) { }

  async ngOnInit(): Promise<void> {
    this.data = this.activatedRoute.snapshot.paramMap.get('id') || '';
    await this.authenticationService.validateUserBySecretUuid(this.data);
    this.store.dispatch(logout());
    this.confirmed = true;
  }

}
