import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  SubRegistrationTableSelectComponent
} from './components/sub-registration-table-select/sub-registration-table-select.component';
import {SharedModule} from "../shared/shared.module";
import {EditNoteDialogComponent} from './components/edit-note-dialog/edit-note-dialog.component';
import {StateEditDialogComponent} from './components/state-edit-dialog/state-edit-dialog.component';
import {ExpiringSubRegCardsComponent} from './components/expiring-sub-reg-cards/expiring-sub-reg-cards.component';
import {PendingSubRegCardComponent} from './components/pending-sub-reg-card/pending-sub-reg-card.component';
import {RouterModule} from "@angular/router";
import { CanSeeCertificatePipe } from './pipes/can-see-certificate.pipe';
import { ChooseMicsTheoryDateDialogComponent } from './components/choose-mics-theory-date-dialog/choose-mics-theory-date-dialog.component';


@NgModule({
  declarations: [
    SubRegistrationTableSelectComponent,
    EditNoteDialogComponent,
    StateEditDialogComponent,
    ExpiringSubRegCardsComponent,
    PendingSubRegCardComponent,
    CanSeeCertificatePipe,
    ChooseMicsTheoryDateDialogComponent,
  ],
  exports: [
    SubRegistrationTableSelectComponent,
    ExpiringSubRegCardsComponent,
    PendingSubRegCardComponent,
    CanSeeCertificatePipe,
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
  ]
})
export class SubRegistrationModule {
}
