export class UserStatusDto {
  id!: string;
  active?: boolean;
  role?: string;
  blocked?: boolean;
  fiscalCode?: string;
  displayName?: string;
  companyName?: string;
  mail?: string;
}
