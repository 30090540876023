import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonEditComponent } from './components/lesson-edit/lesson-edit.component';
import { SharedModule } from "../shared/shared.module";
import { TeacherAdderComponent } from './components/teacher-adder/teacher-adder.component';
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { TeacherCreationComponent } from './components/teacher-creation/teacher-creation.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { LessonListComponent } from './components/lesson-list/lesson-list.component';
import { TranslateTeacherTypePipe } from './pipes/translate-teacher-type/translate-teacher-type.pipe';
import { LessonsHoursPipe } from './pipes/lessons-hours/lessons-hours.pipe';
import { LessonsHoursToStringPipe } from './pipes/lessons-hours-to-string/lessons-hours-to-string.pipe';


@NgModule({
  declarations: [
    LessonEditComponent,
    TeacherAdderComponent,
    TeacherCreationComponent,
    LessonListComponent,
    TranslateTeacherTypePipe,
    LessonsHoursPipe,
    LessonsHoursToStringPipe,
  ],
  exports: [
    LessonEditComponent,
    LessonListComponent,
    LessonsHoursPipe,
    LessonsHoursToStringPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxMatSelectSearchModule,
    FlexLayoutModule,
  ],
  providers: [LessonsHoursPipe],
})
export class LessonModule { }
