import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { FullTableModule } from '@overflo-srl/full-table';
import { DynamicFormModule } from '@overflo-srl/dynamic-form';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/modules/material.module';
import { UserManagingComponent } from './components/user-managing/user-managing.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { ActivatedGuard, AdminGuard, MsalWithRedirectGuard } from './guards/admin.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { UserCreationComponent } from './components/user-creation/user-creation.component';
import { PasswordShowerComponent } from './components/password-shower/password-shower.component';
import { EditionModule } from "../edition/edition.module";
import { TraineeModule } from '../trainee/trainee.module';
import { environment } from '../../environments/environment';
import { CompanyManagingComponent } from './components/company-managing/company-managing.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CompanyCreationComponent } from './components/company-creation/company-creation.component';

@NgModule({
  declarations: [
    UserManagingComponent,
    UserDetailsComponent,
    UserCreationComponent,
    PasswordShowerComponent,
    CompanyManagingComponent,
    CompanyDetailsComponent,
    CompanyCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FlexModule,
    EditionModule,
    FlexLayoutModule,
    TraineeModule,
    FullTableModule.forRoot(environment.backendBasePath),
    MaterialModule,
    BrowserAnimationsModule,
    DynamicFormModule,
  ],
  providers: [AdminGuard, ActivatedGuard, MsalWithRedirectGuard],
  exports: [UserDetailsComponent]
})
export class AdminModule {
}
