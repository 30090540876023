import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, mergeAll } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CompanyUserModel } from '../../../admin/models/company-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    protected readonly http: HttpClient,
  ) { }

  getMe(): Observable<CompanyUserModel> {
    const path = `${environment.backendBasePath}/user/me`;
    return this.http.get<CompanyUserModel>(path);
  }

  getUser(id: number): Observable<CompanyUserModel> {
    const path = `${environment.backendBasePath}/user/${id}`;
    return this.http.get<CompanyUserModel>(path);
  }

  getUserByLocalId(localId: string): Observable<CompanyUserModel> {
    const path = `${environment.backendBasePath}/user`;
    const searchQuery = { "localId": localId };
    const params = { "s": JSON.stringify(searchQuery) };
    const resp = this.http.get<Array<CompanyUserModel>>(path, { params });
    return resp.pipe(mergeAll());
  }

  updateUser(user: Partial<CompanyUserModel>) {
    const path = `${environment.backendBasePath}/user/${user.id}`;
    return this.http.patch<CompanyUserModel>(path, user);
  }

  createUser(user: Partial<CompanyUserModel>) {
    const path = `${environment.backendBasePath}/user`;
    return this.http.post<CompanyUserModel>(path, user)
  }

}
