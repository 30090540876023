import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from './components/login.component';
import {StoreModule} from '@ngrx/store';
import {authFeatureKey, reducer} from './reducers/auth.reducer';
import {AuthEffects} from './effects/auth.effect';
import {EffectsModule} from '@ngrx/effects';
import {FlexModule} from '@angular/flex-layout';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(authFeatureKey, reducer),
    FlexModule,
    SharedModule
  ]
})
export class LoginModule { }
