import { Pipe, PipeTransform } from '@angular/core';
import { TranslateTraineeCategory } from 'src/app/shared/enums/trainee-category.enum';

@Pipe({
  name: 'translateTraineeCategory'
})
export class TranslateTraineeCategoryPipe implements PipeTransform {

  transform(value: string): string {
    return TranslateTraineeCategory(value);
  }

}
