import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {
  loginSuccess, logout,
  saveToken
} from '../actions/auth.actions';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers/reducer';
import {AzureRolesEnum} from '../../shared/enums/azure-roles.enum';
import {LocalStorageVariablesEnum} from "../../shared/enums/local-storage-variables.enum";
import { AuthenticationService } from '../../shared/services/authentication/authentication.service';
import {ActivatedGuard} from "../../admin/guards/admin.guard";

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private readonly route: ActivatedRoute,
    private store: Store<AppState>,
    private readonly authenticationService: AuthenticationService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      tap(async action => {

          this.store.dispatch(saveToken(action));
          const url = this.route.snapshot.queryParamMap.get('returnUrl');
          if (action.user.idTokenClaims.newUser){
            const body = {
              id: action.user.localAccountId,
              companyName: action.user.idTokenClaims.extension_companyName,
              fiscalCode: action.user.idTokenClaims.extension_fiscalCode
            }
            await this.authenticationService.setNewUser(body);
          }

          if (action.user.idTokenClaims.hasOwnProperty('extension_Roles') &&
            action.user.idTokenClaims.extension_Roles === AzureRolesEnum.admin){
            if (url){
              await this.router.navigate([url]);
            } else {
            await this.router.navigate(['admin']);
            }
          }
          else {
            if (url){
              await this.router.navigate([url]);
            } else {
            await this.router.navigate(['home']);
            }
          }
        }
      )
    ), {dispatch: false}
  );

  storeTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveToken),
      tap(action => {
        localStorage.setItem(LocalStorageVariablesEnum.token, JSON.stringify(action.accessToken));
        localStorage.setItem(LocalStorageVariablesEnum.user, JSON.stringify(action.user));
      })
    ), {dispatch: false}
  );

  logoutSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(logout),
      tap(async () => {
        await this.router.navigate(['login']);
        localStorage.clear();
        location.reload();
      })
    ), {dispatch: false}
  );


}
