import {createAction, props} from '@ngrx/store';
import {LoginResponseDto, TokenModel} from '../models/login.model';

export const clear = createAction('[Auth] Clean state');

export const loginSuccess = createAction('[Auth] Login Success', props<LoginResponseDto>());

export const loginError = createAction('[Auth] Login Failure', props<{error: string}>());

export const saveToken = createAction('[Auth] save token', props<LoginResponseDto>());

export const logout = createAction('[Auth] Logout');
