import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CptComponent} from './components/cpt/cpt.component';
import {CptFormComponent} from './components/cpt-form/cpt-form.component';
import {AdminModule} from "../admin/admin.module";
import {SharedModule} from "../shared/shared.module";
import {RouterModule} from "@angular/router";
import { CptListComponent } from './components/cpt-list/cpt-list.component';
import {FullTableModule} from "@overflo-srl/full-table";
import {environment} from "../../environments/environment";
import { CptViewComponent } from './components/cpt-view/cpt-view.component';
import { CptCardComponent } from './components/cpt-card/cpt-card.component';
import { RequestCptCardComponent } from './components/request-cpt-card/request-cpt-card.component';

@NgModule({
  declarations: [
    CptComponent,
    CptFormComponent,
    CptListComponent,
    CptViewComponent,
    CptCardComponent,
    RequestCptCardComponent,
  ],
  exports: [
    CptCardComponent,
    RequestCptCardComponent,
  ],
  imports: [
    CommonModule,
    AdminModule,
    SharedModule,
    RouterModule,
    FullTableModule.forRoot(environment.backendBasePath),
  ]
})
export class CptModule {
}
