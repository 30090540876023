import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {lastValueFrom} from 'rxjs';
import {AppState} from '../../../reducers/reducer';
import {Store} from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import {CondOperator, RequestQueryBuilder} from '@nestjsx/crud-request';
import {TraineeModel} from '../../models/trainee.model';
import {PrevediDto} from "../../dtos/prevedi.dto";
import * as dayjs from "dayjs";
import {LocalCeMemberModel} from "../../models/local-ce-member.model";
import {HttpCallerService} from "../http-caller.service";
import { TraineeWorkContractModel, TraineeWorkContractModelFactory } from '../../models/trainee-work-contract.model';
import { InverseTranslateTraineeCategory, TraineeCategoryTranslationEnum } from '../../enums/trainee-category.enum';

@Injectable({
  providedIn: 'root'
})
export class TraineeService extends HttpCallerService{

  prevediToken: any = undefined;
  readonly LOCAL_BOARD_CODE: string = "BS00";

  constructor(protected readonly http: HttpClient,
              private readonly store: Store<AppState>) {
    super(http);
  }

  async updatePrevediToken(): Promise<void> {
    const body = {
      username: environment.prevediUser,
      password: environment.prevediPassword
    };
    const a$ = this.http.post<any>(environment.basePrevediPath + '/auth/login', body);
    const data = await lastValueFrom(a$);
    this.prevediToken = data.token.jwt;
  }

  async getCompanyEmployeesFromPrevedi(companyFiscalCode: string): Promise<PrevediDto[]> {

    await this.refreshPrevediToken();

    const headers =  {
      Authorization: `Bearer ${this.prevediToken}`
    }

    const realToken: any = jwt_decode(this.prevediToken);

    const dateToUse = new Date();
    dateToUse.setMonth(dateToUse.getMonth() - environment.monthsForPrevediCheck);
    const dateString = dayjs(dateToUse).format('YYYY-MM-DD');
    const url = environment.basePrevediPath + `/previnet-lav?s={"companyFiscalCode": {"$eq": "${companyFiscalCode}"}, "updated": {"$gte": "${dateString}"}}`
    const a$ = this.http.get<any>(url, {headers});
    const data = await lastValueFrom(a$);
    return data.data;
  }

  async getFilteredPrevediMember(companyFiscalCode: string): Promise<PrevediDto[]> {
    const list = await this.getCompanyEmployeesFromPrevedi(companyFiscalCode)
    const result: PrevediDto[] = [];
    for (const elem of list) {
      const duplicate = result.find(x => x.fiscalCode === elem.fiscalCode);
      if (!duplicate) {
        result.push(elem)
      }
    }
    return result;
  }

  async refreshPrevediToken(): Promise<void> {

    if (!this.prevediToken) {
      await this.updatePrevediToken();
      return;
    }

    const realToken: any = jwt_decode(this.prevediToken);

    // It must update the token 5 minutes before expiration date
    if (new Date(realToken.exp * 1000) < new Date(Date.now() + (5 * 60000))) {
      await this.updatePrevediToken();
    }
  }

  async getTraineeWithData(id: string): Promise<TraineeModel> {
    return await this.get<TraineeModel>('/trainee/get-one/' + id);
  }

  async getLocalCeMembers(companyFiscalCode: string): Promise<LocalCeMemberModel[]> {
    let res: LocalCeMemberModel[]  = [];
    let currentPage = 0;
    let pagesCount = 1;
    do {
      currentPage++;
      const query = RequestQueryBuilder.create();
      query
        .setFilter({field: 'companyFiscalCode', operator: CondOperator.EQUALS, value: companyFiscalCode})
        .setFilter({field: 'enabled', operator: CondOperator.EQUALS,  value: true})
        .setPage(currentPage);

      const data: any = await this.get('/local-ce-member?' + query.query());
      currentPage = data.page;
      pagesCount = data.pageCount;
      res = [...res, ...data.data];
    } while (currentPage < pagesCount);
    return res;
  }

  async updateTrainee(id: string, body: TraineeModel){
    const trainee$ = this.http.patch(environment.backendBasePath + '/trainee/' + id, body);
    return await lastValueFrom(trainee$);
  }

  prevediTraineesToWorkContracts(prevediList: PrevediDto[], existingWorkContracts: TraineeWorkContractModel[]): TraineeWorkContractModel[] {
    const result = [];
    for (const member of prevediList) {
      const oldWorkContract = this.findWorkContractWithFiscalCode(existingWorkContracts, member.fiscalCode);

      if (oldWorkContract) {
        result.push(oldWorkContract);
      } else {
        const workContract = TraineeWorkContractModelFactory.createFromWorkerRegistry(member);
        workContract.type = InverseTranslateTraineeCategory(member.task as TraineeCategoryTranslationEnum);
        workContract.inLocalCE =  member.boardName.includes(this.LOCAL_BOARD_CODE);
        workContract.inOtherCE =  !member.boardName.includes(this.LOCAL_BOARD_CODE);
        result.push(workContract);
      }
    }
    return result;
  }

  findWorkContractWithFiscalCode(workContracts: TraineeWorkContractModel[], fiscalCode: string) {
    if (workContracts && workContracts.length > 0) {
      return workContracts.find(x => x.trainee?.fiscalCode === fiscalCode)
    } else {
      return undefined
    }
  }
}
