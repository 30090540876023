import {TraineeWorkContractModel} from "./trainee-work-contract.model";
import {WorkerAnagraphicInterface} from "../interfaces/worker-anagraphic.interface";

export class TraineeModel {
  id?: number;
  firstName!: string;
  lastName!: string;
  fiscalCode!: string;
  selfCertified?: boolean;
  birthMunicipality?: string;
  birthMunicipalityCode?: string;
  birthDate?: Date;
  workContracts?: TraineeWorkContractModel[];
}

export class TraineeModelFactory {
  static createEmpty(): TraineeModel {
    return {
      firstName: "",
      lastName: "",
      fiscalCode: ""
    }
  }

  static createWithFirstName(firstName: string) {
    const trainee = this.createEmpty();
    trainee.firstName = firstName;
    return trainee;
  }

  static createFromWorkerRegistry(workerAnagraphic: WorkerAnagraphicInterface): TraineeModel {
    const trainee = this.createEmpty();
    trainee.firstName = workerAnagraphic.firstName;
    trainee.lastName = workerAnagraphic.lastName;
    trainee.fiscalCode = workerAnagraphic.fiscalCode;
    return trainee;
  }

}

export class TraineeWithCompanyNameModel extends TraineeModel {

  companyName?: string;
  creationDate?: Date;
  type?: string;
  inLocalCE?: boolean;
  inOtherCE?: boolean;
}
