import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentTableComponent } from './components/document-table/document-table.component';
import { SharedModule } from "../shared/shared.module";
import { CertificateComponent } from './components/certificate/certificate.component';
import { LoadFileComponent } from './components/load-file/load-file.component';
import { EditDocumentDialogComponent } from './components/edit-document-dialog/edit-document-dialog.component';
import { DocumentMenuComponent } from './components/document-menu/document-menu.component';
import { DocumentTypeLabelPipe } from './pipes/document-type-label/document-type-label.pipe';
import { CertificateTimetableComponent } from './components/certificate-timetable/certificate-timetable.component';
import { FullTableModule } from '@overflo-srl/full-table';

@NgModule({
  declarations: [
    DocumentTableComponent,
    CertificateComponent,
    LoadFileComponent,
    EditDocumentDialogComponent,
    DocumentMenuComponent,
    DocumentTypeLabelPipe,
    CertificateTimetableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FullTableModule,
  ],
  exports: [
    LoadFileComponent,
    DocumentMenuComponent,
    DocumentTypeLabelPipe,
  ]
})
export class DocumentModule { }
