import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './components/home/home.component';
import {FlexModule} from '@angular/flex-layout';
import {FooterComponent} from './components/footer/footer.component';
import {MenuComponent} from './components/menu/menu.component';
import {SharedModule} from "../shared/shared.module";
import {AdminModule} from "../admin/admin.module";
import {UserHomeComponent} from './components/user-home/user-home.component';
import {PreRegistrationModule} from "../pre-registration/pre-registration.module";
import {SubRegistrationModule} from "../sub-registration/sub-registration.module";
import {EditionModule} from "../edition/edition.module";
import {CptModule} from "../cpt/cpt.module";

@NgModule({
  declarations: [
    HomeComponent,
    FooterComponent,
    MenuComponent,
    UserHomeComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    SharedModule,
    PreRegistrationModule,
    AdminModule,
    SubRegistrationModule,
    EditionModule,
    CptModule,
  ]
})
export class HomeModule {
}
